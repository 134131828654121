export const environment = {
    production: false,
    version: '24.2.0 (Development)',
    licenseTypeId: '202',
    auth_baseurl_server: 'https://axerrio-accounts-dev.azurewebsites.net',
    tenant_baseurl_server: 'https://multitenant-dev.azurewebsites.net',
    appInsights: {
        instrumentationKey: '2eabd05f-24d5-4e4f-bd2d-cb2853f52531',
    },
};
